<template>
  <div>
    <taller-global-nuevo
      :codigo="codigo"
      @cargarTalleres="cargarTalleres"
    />
    <b-card class="p-3">
      <b-button
        size="md"
        variant="gradient-primary"
        class="mb-2"
        @click="abrirModal"
      >
        <feather-icon
          icon="PlusSquareIcon"
        /> Nuevo programa global
      </b-button>
      <br>
      <div>

        <b-row>
          <b-col
            v-for="(value, key, index) in talleres"
            :key="index"
            xl="4"
            lg="4"
            md="6"
            sm="12"
          >
            <b-card
              cols="12"
              :title="value.nombre"
              :img-src="value.img"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 20rem;"
              class="mb-2 text-center border"
            >
              {{ value.descripcion == 'null' ? '' : value.descripcion }}

              <div class="d-flex flex-nowrap ">
                <b-button
                  size="sm"
                  class="btn-block"
                  variant="outline-primary"
                  :to="{ name: nameRuta, params: { id: value.id}}"
                >
                  <feather-icon
                    icon="TrendingUpIcon"
                    size="16"
                  /> ingresar
                </b-button>

                <b-dropdown
                  variant="link"
                  no-caret
                  class="bordered"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="editarTaller(value)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="eliminarTaller(value)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Eliminar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>

            </b-card>

          </b-col>
        </b-row>

      </div>

    </b-card></div>
</template>

<script>

import {
  BRow, BCol, BCard, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import TallerGlobalNuevo from './TallerGlobalNuevo.vue'
import GlobalStoreModule from './GlobalStoreModule'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    TallerGlobalNuevo,
    BDropdown,
    BDropdownItem,

  },
  props: {
    codigo: {
      type: String,
      default: '#',
    },
  },
  data() {
    return {
      talleres: [],
      nameRuta: '',
    }
  },
  watch: {
    codigo(val) {
      this.cargarTalleres()
      switch (val) {
        case 'OCADE-DEPORTIVO':
          this.nameRuta = 'ocade-servicio-deportivo-talleres'
          break
        case 'OCADE-CULTURAL':
          this.nameRuta = 'ocade-servicio-cultural-talleres'
          break
        case 'OCADE-ALT-COMP':
          this.nameRuta = 'ocade-prodac-programas'
          break
        default:
          break
      }
    },
  },

  mounted() {
    this.cargarTalleres()
    switch (this.codigo) {
      case 'OCADE-DEPORTIVO':
        this.nameRuta = 'ocade-servicio-deportivo-talleres'
        break
      case 'OCADE-CULTURAL':
        this.nameRuta = 'ocade-servicio-cultural-talleres'
        break
      case 'OCADE-ALT-COMP':
        this.nameRuta = 'ocade-prodac-programas'
        break
      default:
        break
    }
  },
  methods: {
    cargarTalleres() {
      store.dispatch('globalStoreModule/fetchTalleres', this.codigo).then(res => {
        this.talleres = res.data.data
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al cargar los talleres (500)!',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
  setup() {
    const GLOBAL_APP_STORE_MODULE_NAME = 'globalStoreModule'

    // Register module
    if (!store.hasModule(GLOBAL_APP_STORE_MODULE_NAME)) store.registerModule(GLOBAL_APP_STORE_MODULE_NAME, GlobalStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GLOBAL_APP_STORE_MODULE_NAME)) store.unregisterModule(GLOBAL_APP_STORE_MODULE_NAME)
    })
    function abrirModal() {
      this.$emit('abrirModalNuevoTallerglobal')
    }
    function editarTaller(value) {
      this.$emit('abrirModalEditarTallerGlobal', value)
    }
    function eliminarTaller(value) {
      this.$swal({
        title: `Esta seguro de eliminar el taller deportivo ${value.nombre}`,
        text: 'Esta acción es irreversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('globalStoreModule/deleteTaller', { codigo: this.codigo, id: value.id }).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Realizado!',
              text: this.codigo === 'OCADE-ALT-COMP' ? 'Programa global eliminado' : 'Taller global eliminado',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.cargarTalleres()
          }).catch(() => {

          })
        }
      })
    }

    return {
      abrirModal,
      editarTaller,
      eliminarTaller,
    }
  },

}
</script>

<style scoped>
article img {
  max-height: 150px;
}
</style>
